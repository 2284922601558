import React, {useEffect} from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import { Section, Container, Button } from "../components/utils"
import Hero from "../components/hero"
import Cta from "../components/cta"
import styled from "styled-components"
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    page: { title, intro, heroImage, highlights, hereForSection, aboutSection, seo },
    testimonials,
  },
}) => {
  gsap.registerPlugin(ScrollTrigger);
  let highlightArray = []
    useEffect(() => {
      gsap.from(highlightArray, {
        scrollTrigger: highlightArray[0],
        start: "top center",
        y: 50,
        opacity: 0,
        stagger: 0.2,
        duration: 0.5,
      });
    }, [])
  return (
    <Layout isHome={true}>
      <SEO title={seo.title}>
        <meta name="description" content={seo.description}/>
      </SEO>
      <Hero image={heroImage.fluid} isHome={true}>
        <h1>{title}</h1>
        <p>{intro}</p>
        <Button to="/contact">GET STARTED</Button>
      </Hero>
      <Section className="text-center">
        <Container skinny>
          {highlights.map(block => {
            if (block.model.apiKey === "text") {
              return (
                <div
                  key={block.id}
                  className="center-ruled"
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              )
            }
          })}
          <Highlights>
            {highlights.map(block => {
              if (block.model.apiKey === "highlight") {
                return (
                  <div key={block.title} ref={el => highlightArray.push(el)}>
                    <Img fixed={block.image.fixed} />
                    <h3>{block.title}</h3>
                    <p>{block.intro}</p>
                  </div>
                )
              }
            })}
          </Highlights>
        </Container>
      </Section>
      <Section color="medium">
        <Container>
          <HereForSection>
            {hereForSection.map(block => (
              <div className="center-ruled">
                <div dangerouslySetInnerHTML={{ __html: block.text }} />
                <Button to={block.buttonLink}>{block.buttonText}</Button>
              </div>
            ))}
          </HereForSection>
        </Container>
      </Section>
      <Section padding="medium">
        <Container>
          <AboutSection className="left-ruled">
            {aboutSection.map(block => {
              return (
                <>
                  <div className="aboutImage">
                    <Img fluid={block.image.fluid} />
                  </div>
                  <div className="aboutText">
                    <div dangerouslySetInnerHTML={{ __html: block.text }} />
                    <Link to="about">Read More</Link>
                  </div>
                </>
              )
            })}
          </AboutSection>
        </Container>
      </Section>
      {testimonials && (
        <Section padding="medium">
          <TestimonialContainer>
            {testimonials.edges.map(({ node: { quote, author, company } }) => (
              <>
                <p className="quote">&ldquo;{quote}&rdquo;</p>
                <p className="meta">
                  <span className="author">{author},</span> {company}
                </p>
              </>
            ))}
          </TestimonialContainer>
        </Section>
      )}

      <Cta padding="medium">
        <Container>
          <h2>Ready to get started?</h2>
          <p>
            Get in touch with a query, or get started finding your next dream
            job!
          </p>
          <Button to="/contact">Contact Us</Button>
        </Container>
      </Cta>
    </Layout>
  )
}

export default IndexPage

const Highlights = styled.div`
  display: grid;
  max-width: 900px;
  margin: 2rem auto;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 4rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  p {
    line-height: 1.4;
  }
`
const HereForSection = styled.div`
  display: grid;
  text-align: center;
  & > div {
    &:first-of-type {
      padding-bottom: 3rem;
      border-bottom: 2px solid ${props => props.theme.accent};
      border-right: none;
    }
    :last-of-type {
      padding-top: 3rem;
    }
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    & > div {
      &:first-of-type {
        padding-bottom: 0;
        padding-right: 3rem;
        border-bottom: none;
        border-right: 2px solid ${props => props.theme.accent};
      }
      :last-of-type {
        padding-top: 0;
        padding-left: 3rem;
      }
    }
  }
  ol {
    padding-left: 0;
    text-align: left;
    margin-bottom: 2.2rem;
    list-style: none;
    counter-reset: custom-counter;
  }
  ol li {
    counter-increment: custom-counter;
    margin-bottom: 0.8rem;
    font-weight: 600;
    font-size: 1.1rem;
  }
  ol li::before {
    content: counter(custom-counter);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.theme.text};
    border-radius: 15px;
    width: 30px;
    height: 30px;
    font-weight: bold;
    margin-right: 18px;
  }
`
const AboutSection = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  .aboutText {
    padding: 3rem;
    padding-top: 120px;
    width: 100%;
    transform: translateY(90px);
    margin-bottom: 90px;
    @media (min-width: 768px) {
      margin-bottom: 0px;
      padding-left: 175px;
      padding-top: 3rem;
      transform: translateX(125px);
      width: calc(100% - 125px);
    }
    z-index: 1;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: ${props => props.theme.background2};
    }
    ::after {
      content: "";
      position: absolute;
      top: 12px;
      left: 12px;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: linear-gradient(
          45deg,
          #d9b427 25%,
          transparent 25%,
          transparent 50%,
          #d9b427 50%,
          #d9b427 75%,
          transparent 75%,
          transparent
        )
        bottom left;
      background-size: 5px 5px;
    }
  }
  .aboutImage {
    width: 180px;
    height: 180px;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: 768px) {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 250px;
      height: 250px;
    }
    position: absolute;
    border-radius: 200px;
    overflow: hidden;
    border: 4px solid ${props => props.theme.accent};
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 20;
  }
`
const TestimonialContainer = styled(Container)`
  text-align: center;
  .quote {
    font-size: 1.6rem;
    font-style: italic;
    margin-bottom: 2rem;
  }
  .meta {
    font-size: 1.3rem;
  }
  .author {
    font-weight: 600;
    position: relative;
    margin-left: 40px;
    ::before {
      content: "";
      position: absolute;
      top: 45%;
      left: -40px;
      width: 20px;
      border-bottom: 4px solid ${props => props.theme.accent};
    }
  }
`

export const query = graphql`
  {
    page: datoCmsHomePage {
      seo {
        title
        description
      }
      title
      intro
      heroImage {
        alt
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      highlights {
        ... on DatoCmsText {
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlight {
          model {
            apiKey
          }
          title
          intro
          image {
            fixed(height: 80) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
      hereForSection {
        text
        buttonText
        buttonLink
      }
      aboutSection {
        text
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    testimonials: allDatoCmsTestimonial {
      edges {
        node {
          quote
          author
          company
        }
      }
    }
  }
`
