import styled from "styled-components"
import { Section } from "./utils"

const Cta = styled(Section)`
  background: ${props => props.theme.background2};
  text-align: center;
  p {
    margin-bottom: 2rem;
  }
`
export default Cta
